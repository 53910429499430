import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { queryClient } from "index";
import React, { FC, useState } from "react";
import { FaEye } from "react-icons/fa";
import {
  findWithdrawel,
  updateWithdrawelNext,
  validateQRCode,
} from "services/api.service";
import { maskDocument } from "utils/formatters";
import { maskCurrency } from "utils/number";
import { Withdrawal } from "..";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import useAuth, { UserPermissions } from "contexts/useAuth";
import { useQuery } from "@tanstack/react-query";

const LabelItem: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <VStack mb={3} width="100%" alignItems="start">
      <Text as={"small"} fontSize="sm" color="gray.600">
        {label}
      </Text>
      <Text mt={"0px !important"} fontSize="md" width="100%">
        {value || "---"}
      </Text>
    </VStack>
  );
};

const SeeWithdrawel: React.FC<{
  data: Withdrawal;
}> = ({ data: dataWithdraw }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState(dataWithdraw);
  const { hasPermission } = useAuth();
  const hasPermWrite = hasPermission(UserPermissions.WITHDRAWALS_WRITE);
  const hasPermAutomatic = hasPermission(UserPermissions.WITHDRAWALS_AUTOMATIC);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedFile({ file: reader.result, name: file.name });
    };
  };

  const isQrCode =
    data.status !== "CANCELLED" &&
    data.status !== "FINISHED" &&
    data.type === "QRCODE" &&
    isOpen;

  const { data: qrCodeData } = useQuery(
    ["findQrCode", data.id],
    () => validateQRCode(data.billetCode),
    {
      enabled: isQrCode,
    }
  );

  const cancelWithdrawel = () => {
    setLoading(true);
    updateWithdrawelNext(data.id, { status: "CANCELED" })
      .then(() => {
        toast({
          title: "Sucesso",
          description: `Saque cancelado com sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
        queryClient.refetchQueries({
          queryKey: ["findAllWithdrawers"],
          type: "active",
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const reopenWithdrawel = () => {
    setLoading(true);
    updateWithdrawelNext(data.id, { status: "PENDING" })
      .then(() => {
        toast({
          title: "Sucesso",
          description: `Saque colocado como Pendente com sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
        queryClient.refetchQueries({
          queryKey: ["findAllWithdrawers"],
          type: "active",
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const aproveWithdrawel = ({
    image,
    manualRelease = true,
    status,
  }: {
    image?: string;
    manualRelease?: boolean;
    status?: string;
  }) => {
    setLoading(true);
    updateWithdrawelNext(data.id, {
      status: status ?? data.status === "PENDING" ? "IN_PAYMENT" : "FINISHED",
      image,
      manualRelease,
    })
      .then((response) => {
        if (response.successWithdraw) {
          toast({
            title: "Sucesso",
            description: `Saque enviado automaticamente para fila de processamento`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else
          toast({
            title: "Sucesso",
            description: `Saque ${
              data.status === "PENDING"
                ? "enviado para processamento"
                : "finalizado"
            } com sucesso`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        onClose();
        queryClient.refetchQueries({
          queryKey: ["findAllWithdrawers"],
          type: "active",
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <IconButton
        aria-label="Ver Saque"
        rounded="md"
        size="sm"
        variant="outline"
        icon={<FaEye />}
        onClick={() => {
          setLoading(false);
          findWithdrawel(data.id).then((res) => {
            setData(res);
          });
          onOpen();
        }}
      />
      {isOpen && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Verificar Saque</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <SimpleGrid columns={{ base: 1 }}>
                  {isQrCode && !qrCodeData ? (
                    <>
                      <Alert status="error">
                        <Text>
                          Dados do QRCode não encontrados, pode ser que o QRCode
                          não esteja válido
                        </Text>
                      </Alert>
                    </>
                  ) : undefined}
                  <LabelItem label="Cliente" value={data.Customer?.name} />
                  <LabelItem
                    label="Documento"
                    value={maskDocument(data.Customer?.document_number)}
                  />
                  <LabelItem
                    label="Valor do Saque"
                    value={maskCurrency(data.amount)}
                  />{" "}
                  <LabelItem
                    label="Valor da Taxa"
                    value={maskCurrency(data.fee)}
                  />{" "}
                  <LabelItem
                    label="Valor Final"
                    value={maskCurrency(+data.amount + +data.fee)}
                  />
                  <LabelItem label="Descrição" value={data.description} />
                </SimpleGrid>
                {data.type === "BILLET" ? (
                  <>
                    <Text fontWeight="semibold">Dados Boleto</Text>
                    <Divider mb={2} />
                    <SimpleGrid columns={{ base: 1 }}>
                      <LabelItem
                        label="Vencimento"
                        value={
                          data.dueDate
                            ? `${DateTime.fromISO(data.dueDate).toFormat(
                                "dd/MM/yyyy"
                              )}`
                            : "Sem data de vencimento"
                        }
                      />
                      <LabelItem label="Boleto" value={data.billetCode} />
                      <Button
                        colorScheme="green"
                        size={"sm"}
                        variant={"outline"}
                        onClick={() => {
                          navigator.clipboard.writeText(data.billetCode);
                          toast({
                            title: "Sucesso",
                            description: `Boleto copiado com sucesso`,
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                        }}
                      >
                        Copiar
                      </Button>
                    </SimpleGrid>
                  </>
                ) : undefined}

                {data.type === "QRCODE" ? (
                  <>
                    <Text fontWeight="semibold">Pix Copia e Cola</Text>
                    <Divider mb={2} />
                    <SimpleGrid columns={{ base: 1 }}>
                      <LabelItem
                        label="Pix Copia e Cola"
                        value={data.billetCode}
                      />
                      <LabelItem
                        label="Valor"
                        value={maskCurrency(data.amount)}
                      />
                      {qrCodeData && (
                        <>
                          {qrCodeData?.payload?.calendario && (
                            <>
                              <LabelItem
                                label="Data de criação"
                                value={DateTime.fromISO(
                                  qrCodeData?.payload.calendario.criacao
                                ).toFormat("dd/MM/yyyy HH:mm:ss")}
                              />

                              {qrCodeData.payload.calendario.expiracao ? (
                                <LabelItem
                                  label="Data de Vencimento"
                                  value={DateTime.fromISO(
                                    qrCodeData?.payload.calendario.criacao
                                  )
                                    .plus({
                                      seconds:
                                        qrCodeData?.payload.calendario
                                          .expiracao,
                                    })
                                    .toFormat("dd/MM/yyyy HH:mm:ss")}
                                />
                              ) : (
                                <>
                                  <LabelItem
                                    label="Data de Vencimento"
                                    value={DateTime.fromISO(
                                      qrCodeData?.payload.calendario
                                        .dataDeVencimento
                                    ).toFormat("dd/MM/yyyy")}
                                  />
                                  <LabelItem
                                    label="Original"
                                    value={maskCurrency(
                                      qrCodeData?.payload?.valor?.original
                                    )}
                                  />
                                  <LabelItem
                                    label="Abatimento"
                                    value={maskCurrency(
                                      qrCodeData?.payload?.valor?.abatimento
                                    )}
                                  />

                                  <LabelItem
                                    label="Desconto"
                                    value={maskCurrency(
                                      qrCodeData?.payload?.valor?.desconto
                                    )}
                                  />

                                  <LabelItem
                                    label="Juros"
                                    value={maskCurrency(
                                      qrCodeData?.payload?.valor?.juros
                                    )}
                                  />

                                  <LabelItem
                                    label="Multa"
                                    value={maskCurrency(
                                      qrCodeData?.payload?.valor?.multa
                                    )}
                                  />

                                  <LabelItem
                                    label="Valor a pagar"
                                    value={maskCurrency(
                                      qrCodeData?.payload?.valor?.final ??
                                        qrCodeData?.payload?.valor?.original ??
                                        qrCodeData.transactionAmount
                                    )}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </SimpleGrid>
                  </>
                ) : undefined}

                {data.type === "DICT" ? (
                  <>
                    <Text fontWeight="semibold">Dados bancários</Text>
                    <Divider mb={2} />
                    <SimpleGrid columns={{ base: 1 }}>
                      <>
                        {" "}
                        <LabelItem
                          label="Chave Pix"
                          value={data.CustomerAccounts?.pix}
                        />
                      </>
                    </SimpleGrid>
                  </>
                ) : undefined}
                {data.CustomerAccounts?.bankIspb && (
                  <>
                    <LabelItem
                      label="Banco"
                      value={data.CustomerAccounts?.bankIspb}
                    />
                    <LabelItem
                      label="Agência"
                      value={data.CustomerAccounts?.agency}
                    />
                    <LabelItem
                      label="Conta"
                      value={data.CustomerAccounts?.account}
                    />

                    <LabelItem
                      label="Nome Recebedor"
                      value={data.CustomerAccounts?.accountHolder}
                    />
                    <LabelItem
                      label="Documento recebedor"
                      value={data.CustomerAccounts?.accountHolderDocument}
                    />
                  </>
                )}
                {data.WithdrawelSulcredi?.length ? (
                  <Box mt={4} border="ButtonShadow">
                    <Text fontWeight="semibold" mb={4}>
                      Comprovante
                    </Text>
                    <LabelItem
                      label="Status Pagamento"
                      value={
                        data.WithdrawelSulcredi[0].status === 2
                          ? "Pago"
                          : "Pendente"
                      }
                    />
                    <LabelItem
                      label="Id de Pagamento"
                      value={data.WithdrawelSulcredi[0].endToEndId}
                    />
                    <LabelItem
                      label="Pago em"
                      value={DateTime.fromISO(
                        data.WithdrawelSulcredi[0].updatedAt
                      ).toFormat("dd/MM/yyyy HH:mm:ss")}
                    />
                  </Box>
                ) : null}

                {data.imageUrl && (
                  <Box mt={4}>
                    <Text fontWeight="semibold">Download do comprovante</Text>
                    <Divider mb={2} />
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        saveAs(data.imageUrl);
                      }}
                    >
                      Download
                    </Button>
                  </Box>
                )}
                {!hasPermWrite ? undefined : (
                  <>
                    {["IN_PAYMENT", "FINISHED"].includes(data.status) && (
                      <Box mt={4}>
                        <Text fontWeight="semibold">Envio do comprovante</Text>
                        <Divider mb={2} />{" "}
                        <Input
                          type="file"
                          lang="pt-br"
                          aria-label="Selecione um arquivo"
                          accept=".pdf,.jpg,.jpeg,.png"
                          onChange={handleFileInputChange}
                        />
                        <p>
                          {selectedFile
                            ? selectedFile.name
                            : "Selecione um arquivo"}
                        </p>
                      </Box>
                    )}
                  </>
                )}
              </ModalBody>

              {!hasPermWrite ? undefined : (
                <ModalFooter width="100%">
                  <VStack spacing={4} w={"100%"}>
                    {!["PENDING"].includes(data.status) && (
                      <Button
                        colorScheme="yellow"
                        w={"100%"}
                        isLoading={loading}
                        onClick={() => {
                          reopenWithdrawel();
                        }}
                      >
                        Marcar Pendente
                      </Button>
                    )}
                    {["PENDING", "IN_PAYMENT", "FINISHED"].includes(
                      data.status
                    ) && (
                      <>
                        <Button
                          colorScheme="red"
                          w={"100%"}
                          isLoading={loading}
                          onClick={() => {
                            cancelWithdrawel();
                          }}
                        >
                          Cancelar
                        </Button>
                        {["IN_PAYMENT", "PENDING"].includes(data.status) &&
                          hasPermAutomatic && (
                            <Button
                              colorScheme="green"
                              w={"100%"}
                              isLoading={loading}
                              onClick={() => {
                                aproveWithdrawel({
                                  image: selectedFile?.file,
                                  manualRelease: false,
                                  status: "IN_PAYMENT",
                                });
                              }}
                            >
                              Envio Automático
                            </Button>
                          )}
                        {data.status !== "PENDING" && (
                          <Button
                            colorScheme="green"
                            isLoading={loading}
                            w={"100%"}
                            onClick={() => {
                              aproveWithdrawel({
                                image: selectedFile?.file,
                              });
                            }}
                          >
                            {data.status === "FINISHED"
                              ? "Atualizar"
                              : "Finalizar"}
                          </Button>
                        )}
                        {data.status === "PENDING" && (
                          <Button
                            colorScheme="green"
                            w={"100%"}
                            isLoading={loading}
                            onClick={() => {
                              aproveWithdrawel({ manualRelease: true });
                            }}
                          >
                            {"Envio Manual"}
                          </Button>
                        )}
                      </>
                    )}
                  </VStack>
                </ModalFooter>
              )}
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default SeeWithdrawel;
